<template>
    <v-dialog v-model="dialog" persistent max-width="390">
        <v-card>

            <v-card-text>
                <p class="text-center text-h5 pt-6 font-weight-bold" style="color: black;">
                    Congratulations
                </p>

                <div class="d-flex justify-center flex-column align-center">
                    <img src="@/assets/tick.gif" alt="" srcset="" width="100" />

                    <p class="text-body-1" style="color: black;">
                        Thank you for submitting your secure file upload. Our account
                        management team has been notified and no additional steps are required
                        on your side.
                    </p>
                </div>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialog = false"> OK </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
        };
    },
};
</script>

<style></style>
