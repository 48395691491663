/* eslint-disable */
<template>
  <div>
    <v-app v-if="['login', 'forgotPassword'].includes($route.name)">




      <router-view />

    </v-app>
    <v-app v-show="!['login', 'forgotPassword'].includes($route.name)">
      <v-main>

        <SideBarVue />
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import SideBarVue from './components/SideBar.vue';
// import HomeView from './views/HomeView.vue';


export default {
  components: { SideBarVue },
  name: 'App',

  data: () => ({
    //
  }),
};
</script>


<style></style>