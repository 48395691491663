<template>
    <div>
        <!-- <v-navigation-drawer v-model="drawer" class="grey grey darken-2" dark expand :clipped="$vuetify.breakpoint.lgAndUp"
            app>
            <v-list dense>
                <template v-for="item in itemsDrawer">
                    <v-list-group v-if="item.children" :key="item.text" v-model="item.model" :prepend-icon="item.icon">
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
<v-list-item v-for="(child, i) in item.children" :key="i" link :to="child.link">
    <v-list-item-icon v-if="child.icon">
        <v-icon>{{ child.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
        <v-list-item-title>
            {{ child.text }}
        </v-list-item-title>
    </v-list-item-content>
</v-list-item>
</v-list-group>
<v-list-item v-else :key="item.text" link :to="item.link">
    <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
        <v-list-item-title>
            {{ item.text }}
        </v-list-item-title>
    </v-list-item-content>
</v-list-item>
</template>
</v-list>
</v-navigation-drawer> -->

        <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="#070053" dark class="top-bar">

            <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer" /> -->

            <img src="../assets/white-getscaled.png" alt="" width="230" />
            <div>
                <v-btn depressed @click="myFiles" class="" color="#D75D3F" v-if="currentPage">
                    {{ currentPage }}
                </v-btn>
                <!-- <v-btn depressed @click="myFiles" class="" color="#D75D3F">
                        My Files
                    </v-btn> -->
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">

                        <v-avatar v-bind="attrs" v-on="on">
                            <v-icon dark x-large>
                                mdi-account-circle
                            </v-icon>
                        </v-avatar>
                    </template>
                    <v-list>
                        <v-list-item link @click="resetPassword">
                            <v-list-item-title>Reset Password</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="logout">
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>



        </v-app-bar>
    </div>
</template>
<script>
// import axios from 'axios'
export default {
    data() {
        return {
            drawer: true,


            itemsDrawer: [
                {
                    icon: 'mdi-security',
                    text: 'Secure Upload',
                    link: '/'
                },
                {
                    icon: 'mdi-file-cloud',
                    text: 'My Files ',
                    link: '/my-files'
                }


            ],
            data: null,

            name: null,
            loading: false
        }
    },

    computed: {
        currentPage() {
            let page = ''

            if (this.$route.path == '/my-files') {
                page = 'Upload Files'
                return page
            }
            if (this.$route.path == '/') {
                page = 'My Files'
                return page
            }
        }
    },

    methods: {
        logout() {
            this.loading = true
            localStorage.removeItem('token')
            this.$router.push('/login')
            this.loading = false
        },
        resetPassword() {
            this.$router.push('/reset-password')
        },
        myFiles() {
            if (this.$route.path == '/') {
                this.$router.push('/my-files')
            }
            else {
                this.$router.push('/')
            }

        }
    }


}
</script>

<style scoped>
::v-deep .v-toolbar__content {

    justify-content: space-between !important;
    margin-right: 10px;
    margin-left: 10px;
}
</style>