<template>
  <div>

    <Content />
  </div>
</template>

<script>
// import TopHeader from '@/components/TopHeader.vue';


import Content from '@/components/Content.vue';

export default {
  components: { Content }

}
</script>

<style></style>
