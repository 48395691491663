<template>
    <div class="text-center d-flex justify-center mt-8">
        <v-snackbar v-model="snackbar" timeout="5000" top>
            {{ text }}
        </v-snackbar>
        <div>

            <div class="mt-4 ml-12">

                <v-card max-width="600" class="pa-4">

                    <p class="p">
                        Welcome to the GetScaled secure data portal. Our platform utilizes the most robust security
                        features
                        available
                        today, ensuring your data is recorded anonymously and privately. We do not share, sell, or
                        otherwise
                        manipulate
                        any of our client’s data without explicit written consent.
                    </p>
                    <v-card-text>
                        <div class="mt-4 justify-center">

                            <h2 class="text-uppercase">
                                upload files
                            </h2>
                            <p>
                                Upload your documents on our secure platform!
                            </p>
                            <!-- <v-file-input
              multiple
              label="File input"
              v-model="files"
             
            ></v-file-input> -->
                            <div class="document-upload d-flex justify-content-center align-items-center mb-3">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <v-icon x-large color="blue accent-2"> mdi-cloud-upload </v-icon>
                                    <span> Drag and Drop Document here </span>
                                    <span style="text-align: center"> OR </span>
                                    <v-btn depressed color="primary" @click="handleFileUpload">
                                        Browse Files
                                    </v-btn>
                                    <!-- <span class="text-primary cursor-pointer" @click="handleFileUpload"
                                    style="text-align: center">
                                    Browse Files</span> -->
                                    <span v-for="(selectedFiles, index) in files" :key="index">
                                        {{ selectedFiles.name }}
                                    </span>
                                    <input type="file" class="form-control hidden-input" id="documents"
                                        name="documents[]" aria-describedby="documents" ref="documents" multiple hidden
                                        @change="onChange" />
                                </div>
                            </div>

                        </div>
                        <v-btn :loading="loading" @click="uploadFiles">
                            Upload File
                        </v-btn>
                        <!-- <v-card class="mb-3 mt-3" v-for="(file, index) in uploadingFiles" :key="index">
                        <v-card-text>
                            <div class="d-flex justify-space-between">
                                <v-icon x-large color="blue accent-2">mdi-file-cloud</v-icon>

                                <v-btn icon color="indigo" v-if="file.isUploaded" @click="cancelFile(index)">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-btn icon color="green" v-else>
                                    <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                                </v-btn>

                            </div>
                            <div>
                                <div style="min-height: 2px;">
                                    <v-progress-linear v-if="uploading" :value="uploadPercentage" :height="5"
                                        striped></v-progress-linear>
                                </div>
                            </div>



                        </v-card-text>

                    </v-card> -->

                    </v-card-text>

                    <v-divider></v-divider>

                    <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="dialog = false"> Close </v-btn>

                    <v-btn :loading="loading" color="primary" @click="uploadFiles">
                        Upload
                    </v-btn>
                </v-card-actions> -->
                </v-card>
            </div>

        </div>
        <Confirmation ref="confirmation" />

    </div>
</template>

<script>
import axios from "axios";

import Confirmation from './Confirmation.vue';
export default {
    components: { Confirmation },
    data() {
        return {
            dialog: false,
            currentFile: null,
            files: null,
            fielUploaded: false,
            loading: false,
            successMsg: "",
            selectedFile: null,
            uploading: true,
            uploadPercentage: 20,

            snackbar: false,
            text: "",
            timeout: 10000,
            uploadingFiles: [
                {
                    name: 'file 1',
                    isUploaded: false
                },
                {
                    name: 'file 2',
                    isUploaded: true
                },
                {
                    name: 'file 3',
                    isUploaded: false
                }
            ]

        };
    },
    props: {
        dataType: {
            type: String,
            default: "",
        },
    },

    methods: {
        onChange() {
            this.files = [...this.$refs.documents.files];
        },
        handleFileUpload() {
            document.getElementById("documents").click();
        },
        uploadFiles() {
            if (this.files) {
                this.loading = true;
                let data = new FormData();

                for (var i = 0; i < this.$refs.documents.files.length; i++) {
                    let file = this.$refs.documents.files[i];
                    data.append("file", file);
                }
                this.loading = true;

                axios
                    .post(`${process.env.VUE_APP_API_URL}upload`, data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    })
                    .then((res) => {
                        this.files = null;
                        this.fielUploaded = true;
                        this.loading = false;

                        this.$emit("file-uploaded");



                        this.$refs.confirmation.dialog = true

                    })
                    .catch((error) => {
                        this.loading = false
                        if (error) {
                            console.log(error.response.status, 'asdfasd');
                            if (error.response.status == 401) {
                                this.localStorage.removeItem('token')
                                this.$router.push("/login");
                            }
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log("Error", error.message);
                        }
                    });

            } else {
                this.snackbar = true;
                this.text = 'Select the file!';
            }





        },

        cancelFile(incex) {
            this.uploadingFiles.splice(incex, 1);

        }

    },

};
</script>

<style scoped>
.document-upload {
    min-height: 200px;
    border: 1px dashed;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 400px;
    /* background: linear-gradient(to right, #007bff, #1e90ff); */
}

.cursor-pointer {
    cursor: pointer;
}
</style>
